import React, { useEffect, useState, useContext } from "react";

import { isFirefox } from "react-device-detect";
import { createPortal } from "react-dom";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import firefoxPinSecret from "../../utils/firefoxPinSecret";
import ChannelControl from "../videoAlert/ChannelControl";
import { SessionContext } from "../../context/SessionContext";
import { checkProfilePin } from "../../api/user/user";
import localizationStrings from "../../statics/localization"; // prop drilling gerekebilir

const Notification = ({
  text,
  confirmText,
  notConfirmText,
  onClickConfirm,
  onClickNotConfirm,
  dontUsePortal,
  data,
  isPassword,
  icon,
  sendResult,
  showCloseButton,
  viewComponent
}) => {
  const [viewClass, setViewClass] = useState("");
  const [profilePassword, setProfilePassword] = useState("");
  const [i, setI] = useState(0);
  const [pinText, setPinText] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setViewClass("open");
    }, 20);
  }, []);

  const checkPassword = async (password) => {
    const profileInfo = JSON.parse(localStorage.getItem("profile"));
    const { data } = await checkProfilePin(profileInfo?.id, password);
    data && confirmClicked();

    if (!data) {
      setShowAlert(true);
    }
  };

  const confirmClicked = () => {
    setViewClass("");
    if (isPassword) {
      sendResult(true);
    }
    setTimeout(() => {
      onClickConfirm();
    }, 300);
  };

  const notConfirmClicked = () => {
    setViewClass("");

    setTimeout(() => {
      onClickNotConfirm();
    }, 300);
  };

  const renderedEl = (
    <div className={`notification-container ${viewClass}`}>
      <div
        className="notification-box"
        style={{
          width: data?.length ? "40%" : "",
          width: viewComponent ? "auto" : ""
        }}
      >
        {showCloseButton ? (
          <div className="close-row">
            <div className="close" onClick={() => notConfirmClicked()}>
              <PopupCloseIcon />
            </div>
          </div>
        ) : null}
        {icon ? <div className="icon">{icon}</div> : null}
        <p className="text">{text}</p>
        {isPassword ? (
          <form
            id="passwordModalForm"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (isPassword) {
                  profilePassword && checkPassword(profilePassword);
                } else {
                  confirmClicked();
                }
              }
            }}
          >
            {!isFirefox ? (
              <input
                className="profile-input pin"
                type="number"
                name="profilePin"
                autoComplete="off"
                autoFocus={true}
                id="passwordModalPin"
                value={profilePassword}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                  e.preventDefault()
                }
                onChange={(e) => {
                  if (e.target.value.length > 4) {
                    e.target.value = e.target.value.slice(0, 4);
                  }
                  setProfilePassword(e.target.value);
                  showAlert && setShowAlert(false);
                }}
                placeholder="Pin"
              />
            ) : (
              <input
                className="profile-input"
                autoComplete="off"
                onMouseUp={(e) => {
                  let [pin, i] = firefoxPinSecret(null, e);
                  setI(i);
                  setPinText(pin.replace(/[0-9,•]/g, "•"));
                  setProfilePassword(pin);
                }}
                onKeyDown={(e) => {
                  let [pin, i] = firefoxPinSecret(e, null);
                  setI(i);
                  setPinText(pin.replace(/[0-9,•]/g, "•"));
                  setProfilePassword(pin);
                }}
                onChange={(e) => {
                  e.target.value = pinText;
                  e.target.selectionEnd = i;
                  e.target.selectionStart = i;
                  showAlert && setShowAlert(false);
                }}
                autoFocus={true}
                name="profilePin"
                id="passwordModalPin"
                type="text"
                //value={profilePassword.replace(/[0-9]/g, "•")}
                placeholder="Pin"
              />
            )}
            {showAlert ? ( // wrongPinText herhangi bir modal basiminda sorun cikarirsa prop drilling ile al
              <div className="alert-text">
                {localizationStrings.wrongPinText}
              </div>
            ) : null}
          </form>
        ) : null}

        {viewComponent ? (
          <div>
            <ChannelControl />
          </div>
        ) : null}

        <div className="button-wrapper">
          <div
            className={`button-notification notconfirm ${
              !notConfirmText?.length ? "hide" : ""
            }`}
            onClick={() => {
              notConfirmClicked();
            }}
          >
            <span>{notConfirmText}</span>
          </div>

          <div
            className={`button-notification confirm ${
              !confirmText?.length ? "hide" : ""
            }`}
            style={{
              marginLeft:
                confirmText?.length && notConfirmText?.length ? "30px" : ""
            }}
            onClick={() => {
              if (isPassword) {
                profilePassword && checkPassword(profilePassword);
              } else {
                confirmClicked();
              }
            }}
          >
            <span>{confirmText}</span>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: data?.length ? "30px" : "" }}>
          {data
            ? data.map((item) => {
                return (
                  <div
                    style={{ width: `${100 / data.length}%`, padding: "10px" }}
                  >
                    <img
                      style={{ width: "100%", height: "auto" }}
                      src={
                        item.posterpath + "" + item.posterfilelist.split(";")[5]
                      }
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );

  return dontUsePortal
    ? renderedEl
    : createPortal(renderedEl, document.getElementById("notification_root"));
};

export default Notification;
