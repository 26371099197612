import React, { useContext, useEffect, useRef, useState } from "react";
import { getUserMyTvChannel } from "../../api/ltv/ltv";
import moment from "moment";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import DisplayDate from "../displayDate";
import localizationStrings from "../../statics/localization";
import { getVodDetail } from "../../api/vod/vod";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import { SessionContext } from "../../context/SessionContext";
import LiveIcon from "../../assets/icons/svg/LiveIcon";
import Namespace from "../../assets/icons/svg/Namespace";
import ModalPortal from "../modalPortal";
import ProgramDetail from "../programDetail";
import { useHistory, useLocation } from "react-router-dom";

const MyTvChannelInfo = ({ getProgramPlayUrl, scrollToTop }) => {
  const [programs, setPrograms] = useState([]);
  const [currentProgram, setCurrentProgram] = useState({});
  const [programDetail, setProgramDetail] = useState();
  const [showProgramDetail, setShowProgramDetail] = useState(false);
  const {
    setPrevMytvChannelPrograms,
    setNextMytvChannelPrograms,
    setMytvChannelPosterPath,
    selectedMytvChannelProgram,
    userFrameConfig,
    userInfo,
    setPlayUrl,
    playUrl,
    hasLoggedIn
  } = useContext(SessionContext);
  const programInfo = useRef(null);
  const programDetailRef = useRef(null);
  const selectedProgramRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  let startOfDay = moment().startOf("day").format("YYYYMMDDHHmmss");
  let endOfDay = moment().endOf("day").format("YYYYMMDDHHmmss");

  const getMyTvChannel = async () => {
    const { data } = await getUserMyTvChannel(
      startOfDay,
      endOfDay,
      localStorage.getItem("language")
    );
    if (data.returncode === "0") {
      let arr = data.data;
      setMytvChannelPosterPath(data.posterpath);
      let now = moment().valueOf() - parseInt(localStorage.getItem("timeDiff"));
      let currentProgram = arr.find(
        (item) =>
          moment(item.starttime, "YYYYMMDDHHmmss").valueOf() <= now &&
          moment(item.endtime, "YYYYMMDDHHmmss").valueOf() > now
      );

      if (currentProgram) {
        onSelectProgram(currentProgram);
        selectedProgramRef.current = currentProgram;
        let beginTime = moment(
          currentProgram.starttime,
          "YYYYMMDDHHmmss"
        ).valueOf();
        let endTime = moment(
          currentProgram.endtime,
          "YYYYMMDDHHmmss"
        ).valueOf();
        currentProgram.progress =
          ((now - beginTime) / (endTime - beginTime)) * 100;
        programInfo.current = currentProgram;
        getProgramDetail(currentProgram?.programcode);
        setProgramsToContext(arr, currentProgram);
        setCurrentProgram(currentProgram);
      }

      if (!currentProgram) {
        getProgramPlayUrl(null);
      }

      setPrograms(arr);
    }
  };

  const setProgramsToContext = (programs, currProgram) => {
    let currentIndex = programs.findIndex(
      (item) => item.programcode === currProgram.programcode
    );
    let prevPrograms = programs.slice(0, currentIndex);
    let nextPrograms = programs.slice(currentIndex + 1, programs.length);
    setNextMytvChannelPrograms(nextPrograms);
    setPrevMytvChannelPrograms(prevPrograms);
  };

  const getProgramDetail = async (programCode, openProgramDetail = false) => {
    const { data } = await getVodDetail(programCode);
    if (data.returncode === "0") {
      let data_ = data.data[0];
      data_.starttime = programInfo.current.starttime;
      data_.endtime = programInfo.current.endtime;
      if (!openProgramDetail) {
        setProgramDetail(data_);
      } else {
        programDetailRef.current = data_;
        setShowProgramDetail(true);
      }
    }
  };

  const onSelectProgram = (item, openDetail = false) => {
    getProgramDetail(item.programcode, openDetail);
    programInfo.current = item;
    if (!openDetail) {
      getProgramPlayUrl(item);
      scrollToTop();
      selectedProgramRef.current = item;
    }
  };

  const renderRatingIcon = () => {
    return selectedProgramRef.current?.ratingid === "1" ? (
      <RtukGeneralIcon />
    ) : selectedProgramRef.current?.ratingid === "2" ? (
      <RtukSevenAgeIcon />
    ) : selectedProgramRef.current?.ratingid === "4" ? (
      <RtukThirteenAgeIcon />
    ) : selectedProgramRef.current?.ratingid === "8" ? (
      <RtukAgeIcon />
    ) : null;
  };

  const renderedItems = programs.map((program, index) => {
    return (
      <ProgramItem
        key={program.programcode}
        program={program}
        index={index}
        onSelectProgram={onSelectProgram}
        currentProgram={currentProgram}
      />
    );
  });

  useEffect(() => {
    getMyTvChannel(startOfDay, endOfDay);
  }, []);

  useEffect(() => {
    if (selectedMytvChannelProgram) {
      onSelectProgram(selectedMytvChannelProgram);
    }
  }, [selectedMytvChannelProgram]);

  const renderProgramInfo = () => {
    if ((!!currentProgram && currentProgram?.programcode) || programDetail) {
      return (
        <div>
          <div className="program-title">{programDetail?.programname}</div>
          <div className="program-data-row">
            <div className="program-rating">
              <div style={{ color: "white" }}>{localizationStrings.today}</div>
              <span style={{ margin: "0 5px " }}>-</span>
              <div className="date">
                <DisplayDate onlyHour={true} value={programDetail?.starttime} />
              </div>
              <span style={{ margin: "0 5px" }}>-</span>
              <div className="date">
                <DisplayDate
                  onlyHour={true}
                  value={programDetail?.endtime}
                  style={{ color: "#587086" }}
                />
              </div>
              <div className="program-rating icon">{renderRatingIcon()}</div>
              <div className="program-rating genre" style={{ color: "white" }}>
                {programDetail?.genre}
              </div>
            </div>
          </div>
          <div className="program-description" style={{ marginBottom: "20px" }}>
            {programDetail?.description}
          </div>
        </div>
      );
    } else {
      return (
        <div className="no-program-info">{localizationStrings.noProgram}</div>
      );
    }
  };

  return (
    <div id="vert-program-list" style={{}}>
      <div className="channel-info-container">{renderProgramInfo()}</div>
      <div id="mytv-program-list-container" className="mytv-program-container">
        {renderedItems}
      </div>
      {showProgramDetail ? (
        <ModalPortal viewClass="vod-modal">
          <ProgramDetail
            myTvChannelProgram={programDetailRef.current}
            closeModal={(isPlay) => {
              if (isPlay) {
                getProgramPlayUrl(programDetailRef.current);
                scrollToTop();
                setProgramDetail(programDetailRef.current);
              }
              setShowProgramDetail(false);
            }}
            userFrameConfig={userFrameConfig}
            userInfo={userInfo}
            location={location}
            history={history}
            canPlay={
              currentProgram?.endtime > programDetailRef.current?.endtime
            }
            setPlayUrl={setPlayUrl}
            playUrl={playUrl}
            hasLoggedIn={hasLoggedIn}
          />
        </ModalPortal>
      ) : null}
    </div>
  );
};

const ProgramItem = ({ program, onSelectProgram, currentProgram }) => {
  const programRef = useRef(null);

  useEffect(() => {
    if (program && currentProgram)
      if (program.programcode === currentProgram.programcode) {
        document
          .getElementById("mytv-program-list-container")
          .scrollTo(
            0,
            programRef.current.offsetTop - programRef.current.offsetHeight - 120
          );
      }
  }, [program, currentProgram]);

  return (
    <div
      ref={programRef}
      className={`program-row ${
        currentProgram?.endtime < program?.endtime ? "previousprog" : ""
      } 
    `}
    >
      <div className="box info">
        <div
          className="title"
          title={localizationStrings.watch}
          onClick={() => {
            if (currentProgram?.endtime > program?.endtime) {
              onSelectProgram(program);
            } else {
              onSelectProgram(program, true);
            }
          }}
        >
          {program?.programname}
        </div>
        <div className="genre-passingtime">
          <div className="genre">{program?.genre} </div>
          <span style={{ margin: "0 5px" }}>-</span>
          <div className="date">
            <DisplayDate onlyHour={true} value={program?.starttime} />
          </div>
          <span style={{ margin: "0 5px" }}>→</span>
          <div className="date">
            <DisplayDate onlyHour={true} value={program?.endtime} />
          </div>
          <div className="passing-time"></div>
        </div>
      </div>
      <div className="image-icon-group">
        <div
          className="box progress-bar "
          style={{
            opacity:
              currentProgram?.starttime === program?.starttime ? "1" : "0"
          }}
        >
          <div className="current-program-play-icon">{<LiveIcon />}</div>
          <span className="current-program-play-text">
            {localizationStrings.live}
          </span>
        </div>
        <div
          className="box-namespace"
          onClick={() => {
            onSelectProgram(program, true);
          }}
        >
          {<Namespace />}
        </div>
        <div className="rating">
          {program?.ratingid === "1" ? (
            <RtukGeneralIcon />
          ) : program?.ratingid === "2" ? (
            <RtukSevenAgeIcon />
          ) : program?.ratingid === "4" ? (
            <RtukThirteenAgeIcon />
          ) : program?.ratingid === "8" ? (
            <RtukAgeIcon />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MyTvChannelInfo;
