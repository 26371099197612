import React, { useRef, useState } from "react";
import FacebookIcon from "../../assets/icons/svg/FacebookIcon";
import InstagramIcon from "../../assets/icons/svg/InstagramIcon";
import LinkedinIcon from "../../assets/icons/svg/LinkedinIcon";
import ShareIcon from "../../assets/icons/svg/ShareIcon";
import TwitterIcon from "../../assets/icons/svg/TwitterIcon";
import YayIcon from "../../assets/icons/svg/YayIcon";
import CopyIcon from "../../assets/icons/svg/CopyIcon";
import localizationStrings from "../../statics/localization";
import VisibilitySensor from "react-visibility-sensor";
import { copyToClipboard } from "../../utils/copyToClipboard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

const ShareAction = ({ vod, programInfo, isPlayer, selectedChannel }) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const isVisibleRef = useRef(false);
  const [shareUrl, setShareUrl] = useState("");
  const [message, setMessage] = useState("");

  const shareClicked = () => {
    let dataInfo;
    if (selectedChannel?.id) {
      dataInfo = encodeURIComponent(`2|${selectedChannel?.id}`);
    } else {
      dataInfo = encodeURIComponent(`1|${vod?.id}`);
    }

    setMessage(
      localizationStrings.formatString(localizationStrings.shareText, {
        contentName: vod?.title ? `"${vod?.title}"` : `"${programInfo?.title}"`
      })
    );
    const shareUrl = `${window.location.origin}/?datainfo=${dataInfo}`;
    setShareUrl(shareUrl);
  };

  return (
    <div
      className={`action-button secondary ${
        showShareOptions ? "selected" : ""
      }`}
      onClick={() => {
        shareClicked();
        setShowShareOptions(!showShareOptions);
        if (showShareOptions) {
          setTimeout(() => {
            setIsRight(!isVisibleRef.current);
          }, 300);
        } else {
          setIsRight(!isVisibleRef.current);
        }
      }}
    >
      <span className="icon">
        <ShareIcon />
      </span>
      <VisibilitySensor>
        {({ isVisible }) => {
          isVisibleRef.current = isVisible;
          return (
            <div
              className={`custom-tooltip ${
                isRight ? "tooltip-right" : "tooltip-left"
              } ${showShareOptions ? "open" : ""}  ${
                isPlayer ? "player-tooltip" : ""
              }`}
            >
              {!isPlayer ? (
                <div className="tooltip-text">
                  {localizationStrings.shareNow}
                </div>
              ) : null}
              <div
                className="tooltip-button"
                onClick={() => {
                  copyToClipboard(shareUrl);
                }}
              >
                <CopyIcon />
              </div>
              <div className="tooltip-button">
                <WhatsappShareButton
                  url={shareUrl}
                  title="whatsapp"
                  quote={message}
                  separator={`\n`}
                  windowHeight={550}
                  windowWidth={650}
                >
                  <span className="tooltip-icon">
                    <WhatsappIcon
                      size={"100%"}
                      round={true}
                      bgStyle={{ fill: "white" }}
                      iconFillColor="black"
                      className="share-icon"
                    />
                  </span>
                </WhatsappShareButton>
              </div>
              <div className="tooltip-button">
                <FacebookShareButton
                  url={shareUrl}
                  title="facebook"
                  quote={message}
                  separator={`\n`}
                  windowHeight={550}
                  windowWidth={650}
                >
                  <span className="tooltip-icon">
                    <FacebookIcon />
                  </span>
                </FacebookShareButton>
              </div>
              <div className="tooltip-button">
                <TwitterShareButton
                  url={shareUrl}
                  title={message}
                  separator={`\n`}
                  windowHeight={450}
                  windowWidth={650}
                >
                  <span className="tooltip-icon">
                    <TwitterIcon />
                  </span>
                </TwitterShareButton>
              </div>
              <div className="tooltip-button">
                <LinkedinShareButton
                  url={shareUrl}
                  title={message}
                  separator={`\n`}
                  windowHeight={450}
                  windowWidth={650}
                >
                  <span className="tooltip-icon">
                    <LinkedinIcon />
                  </span>
                </LinkedinShareButton>
              </div>
              {/* <div className="tooltip-button">
                <span className="tooltip-icon">
                  <YayIcon />
                </span>
              </div> */}
            </div>
          );
        }}
      </VisibilitySensor>
    </div>
  );
};

export default ShareAction;
