import React, { useEffect, useRef, useState } from "react";
import Namespace from "../../assets/icons/svg/Namespace";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import DisplayDate from "../displayDate";
import moment from "moment";
import VisibilitySensor from "react-visibility-sensor";
import defaultHorizontalPoster from "../../assets/images/default-vod-horizontal-poster.png";
import LiveIcon from "../../assets/icons/svg/LiveIcon";
import localizationStrings from "../../statics/localization";
import ImageView from "../imageView";
import defaultPoster from "../../assets/images/no-portrait-poster.png";
import { getProgramsById } from "../../api/ltv/ltv";

const emptyProgram = [
  {
    epgProgramId: "",
    seriesId: "",
    title: "Empty data",
    startTime: undefined,
    endTime: undefined,
    category: "empty",
    posters: [
      {
        url: "empty",
        type: "Vertical"
      }
    ],
    casts: null,
    description: "empty",
    watchable: false,
    current: false,
    duration: 0,
    rating: {
      age: "18+",
      categories: null
    },
    broadcastType: null
  }
];
const TvGuideRow = ({ channelid, date, selectedChannel, onClickProgram }) => {
  const [posterUrl, setPosterUrl] = useState("");
  const [programs, setPrograms] = useState(emptyProgram);

  const getProgramsByChannel = async (formatedDate) => {
    try {
      const data = await getProgramsById(channelid, formatedDate);
      console.log("program data", data);
      if (data.status === 200 && data?.data.length > 0) {
        setPrograms(data?.data);
      } else {
        setPrograms(emptyProgram);
      }
      //setAllPrograms(data.data);
    } catch (err) {
      console.log("program data error", err);

      setPrograms(emptyProgram);
    }
  };

  useEffect(() => {
    if (!!date) {
      let formatedDate = moment().add(date.id, "days").format("DD.MM.yyyy");
      getProgramsByChannel(formatedDate);
    }
  }, [date]);

  const renderedItems = programs.map((item, index) => {
    return (
      <TvGuideRowItem
        item={item}
        selectedChannel={selectedChannel}
        index={index}
        channelid={channelid}
        onClickProgram={onClickProgram}
      />
    );
  });

  return <div className="tvguide-row">{renderedItems}</div>;
};

const TvGuideRowItem = ({
  item,
  selectedChannel,
  index,
  channelid,
  onClickProgram
}) => {
  const itemRef = useRef();
  let beginTime = moment(
    item?.startTime
    //item.begintime ? item.begintime : item.starttime
  ).utc();
  let endTime = moment(item.endTime).utc();
  let cnt = 0;
  let now = moment().utc();
  let isCurrent = beginTime <= now && now < endTime;
  let progress = null;

  if (isCurrent) {
    progress = ((now - beginTime) / (endTime - beginTime)) * 100;
  }

  useEffect(() => {
    if (
      item &&
      selectedChannel &&
      selectedChannel?.id === channelid &&
      isCurrent
    ) {
      console.log(
        "scroll",
        itemRef.current.offsetLeft,
        itemRef.current.offsetTop - itemRef.current.offsetHeight - 120,
        "channel scrollTop",
        document.getElementById("vertical-channel-list-channellist-3").scrollTop
      );
      const channelScrollTop = document.getElementById(
        "vertical-channel-list-channellist-3"
      )?.scrollTop;
      console.log("channelScrollTop", channelScrollTop);

      const horizontalProgramList = document.getElementById("tvguideWrapper");
      if (horizontalProgramList) {
        setTimeout(() => {
          horizontalProgramList.scrollTo(
            itemRef.current.offsetLeft - 25,
            channelScrollTop ??
              itemRef.current.offsetTop - itemRef.current.offsetHeight - 120
          );
        }, 250);
      }

      console.log("scrolll tv guide");
    }
  }, [item]);

  return (
    <VisibilitySensor>
      {({ isVisible }) => {
        isVisible && ++cnt;

        return (
          <div
            key={index + item.epgProgramId}
            className="multi-program-item-box"
            ref={itemRef}
          >
            <div className="program-item-inner">
              <div className="program-info">
                <div className="inner-info">
                  <div
                    className="title"
                    onClick={() => onClickProgram(item, isCurrent)}
                    title={item.title}
                  >
                    {item.title}
                  </div>
                  <div className="genre-date">
                    {item.category} -{" "}
                    {moment.unix(item?.startTime / 1000).format("HH:mm")}
                    <span style={{ margin: "0 5px" }}>→</span>{" "}
                    {moment.unix(item?.endTime / 1000).format("HH:mm")}
                  </div>
                </div>
                <div className="inner-rating">
                  <div
                    className="info-button"
                    onClick={() => onClickProgram(item, isCurrent)}
                  >
                    {<Namespace />}
                  </div>
                  {item?.rating.age === "G" ? (
                    <RtukGeneralIcon />
                  ) : item?.rating.age === "7+" ? (
                    <RtukSevenAgeIcon />
                  ) : item?.rating.age === "13+" ? (
                    <RtukThirteenAgeIcon />
                  ) : item?.rating.age === "18+" ? (
                    <RtukAgeIcon />
                  ) : null}
                </div>
              </div>
              <div className="program-poster">
                <ImageView
                  src={`${item?.posters?.length ? item?.posters[0]?.url : ""}`}
                  defaultPoster={defaultHorizontalPoster}
                />
                <div
                  className="live-program-indicator"
                  style={{ display: !isCurrent ? "none" : "" }}
                >
                  <span className="icon">
                    <LiveIcon />
                  </span>
                  <span className="text">{localizationStrings.live}</span>
                </div>
              </div>
            </div>
            <div
              className="current-progress"
              style={{ display: !isCurrent ? "none" : "" }}
            >
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        );
      }}
    </VisibilitySensor>
  );
};

export default TvGuideRow;
