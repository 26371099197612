import React, { useEffect, useRef, useState } from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import moment from "moment";
import { getProgramAndNpvrThumbnail } from "../../../api/common/commonApi";
import {
  getCurrentTimeIsFromPip,
  getPltvPaused,
  setCurrentTimeIsFromPip,
  setPltvPaused,
  setTimeUpdateIntervalId
} from "../../../statics/config";
import { isFirefox, isSafari } from "react-device-detect";

const SliderWithTooltip = createSliderWithTooltip(Slider);

const PlayerBar = ({
  program,
  player,
  isTimeshiftEnable,
  setIsLiveEdge,
  isPltv,
  restartTv,
  rewindBackTv,
  isPaused
}) => {
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState(null);
  const liveTracker = useRef(null);
  const programBeginTime = useRef(0);
  const programEndTime = useRef(0);
  const currentProgramProgress = useRef(0);
  const timeDiff = useRef(0);
  const progressRef = useRef(0);
  const sliderRef = useRef(null);
  const afterProgress = useRef(null);
  const isPltvRef = useRef(null);
  const restartTvRef = useRef(null);
  const rewindBackTvRef = useRef(null);
  const isLoadedPltv = useRef(false);
  const timeUpdateIntervalId = useRef(null);
  const isPlayTriggered = useRef(null);
  let currentTimeIsFromPip = getCurrentTimeIsFromPip();
  let now;
  let currentTime;
  let liveCurrentTime;
  let progress_;

  const calcProgress = (val = 0) => {
    now = new Date().getTime() / 1000 - timeDiff.current / 1000; //seconds
    currentTime = player.currentTime(); // it is seconds but different format

    liveCurrentTime = liveTracker.current.liveCurrentTime(); // it is seconds but different format
    // getting progress percentage with this formula: (now - (liveCurrentTime - currentTime) - begintime) / (endtime- begintime)
    progress_ =
      ((now -
        val -
        (liveCurrentTime - currentTime) -
        programBeginTime.current) /
        (programEndTime.current - programBeginTime.current)) *
      1000;

    if (val === 0) {
      setTime(
        moment(now * 1000 - (liveCurrentTime - currentTime) * 1000).format(
          "HH:mm:ss"
        )
      );

      setProgress(progress_);
      progressRef.current = progress_;
    } else {
      return progress_;
    }
  };

  const calcCurrentProgramProgress = () => {
    let now = new Date().getTime() / 1000 - timeDiff.current / 1000;
    let progress_ =
      ((now - programBeginTime.current) /
        (programEndTime.current - programBeginTime.current)) *
      1000;
    currentProgramProgress.current = progress_;
    listenTimeUpdate();
  };

  const seekToSelectedProgress = (value, paused = false) => {
    if (value <= currentProgramProgress.current || paused) {
      let programDuration = programEndTime.current - programBeginTime.current;
      let seekTime = ((progressRef.current - value) * programDuration) / 1000;

      myLogger("programDuration", programDuration);
      myLogger("seekTime", seekTime);

      // if (isPlayTriggered.current) {
      //   player.currentTime(player.currentTime() - seekTime);
      // } else {
      //   player.on("play", () => {
      //     setTimeout(
      //       () => {
      //         player.currentTime(player.currentTime() - seekTime);
      //         isPlayTriggered.current = true;
      //         player.off("play");
      //       },
      //       isSafari ? 200 : 0
      //     );
      //   });
      // }
      if (isSafari) {
        if (isPlayTriggered.current) {
          player.currentTime(player.currentTime() - seekTime);
        } else {
          player.on("play", () => {
            setTimeout(() => {
              player.currentTime(player.currentTime() - seekTime);
            }, 200);
            isPlayTriggered.current = true;
            player.off("play");
          });
        }
      } else {
        setTimeout(() => {
          myLogger("player.currenttime", player.currentTime());
          player.currentTime(player.currentTime() - seekTime);
        }, 200);
      }

      //player.currentTime(player.currentTime() - seekTime);

      setProgress(value);

      // myLogger("seekToSelectedProgress progress",value)

      progressRef.current = value;
    } else {
      if (isPltv) {
        setIsLiveEdge("0");
      } else {
        setProgress(progressRef.current);
      }
    }
    listenTimeUpdate();
  };

  const listenTimeUpdate = () => {
    timeUpdateIntervalId.current && offTimeUpdate();
    timeUpdateIntervalId.current = setInterval(() => {
      // console.log("timeUpdateIntervalId:",timeUpdateIntervalId.current);
      calcProgress();
    }, 1000);

    setTimeUpdateIntervalId(timeUpdateIntervalId.current);
  };

  const offTimeUpdate = () => {
    clearInterval(timeUpdateIntervalId.current);
    setTimeUpdateIntervalId(null);
  };

  useEffect(() => {
    if (program && player) {
      programBeginTime.current = program?.startTime / 1000; // seconds
      programEndTime.current = program?.endTime / 1000; // seconds

      restartTvRef.current = 0;
      isLoadedPltv.current = false;
      isPltvRef.current = false;
      rewindBackTvRef.current = false;
      afterProgress.current = null;
      player.off("loadedmetadata");
      player.on("loadedmetadata", () => {
        isPlayTriggered.current = false;
        offTimeUpdate();
        liveTracker.current = player.getChild("LiveTracker");

        if (restartTvRef.current) {
          restartTvRef.current = 0;
          isLoadedPltv.current = true;
          seekToSelectedProgress(0);
        } else if (
          isPltvRef.current &&
          !rewindBackTvRef.current &&
          !getPltvPaused()
        ) {
          isLoadedPltv.current = true;
          seekToSelectedProgress(afterProgress.current);
        } else {
          restartTvRef.current = 0;
          isLoadedPltv.current = rewindBackTvRef.current;
          calcCurrentProgramProgress();
          calcProgress();
          rewindBackTvRef.current = false;
          if (getPltvPaused()) {
            isLoadedPltv.current = true;
            player.pause();
            setPltvPaused(false);
          }
        }
      });
    }
  }, [program, player]);

  useEffect(() => {
    isPltvRef.current = isPltv;
  }, [isPltv]);

  useEffect(() => {
    if (currentTimeIsFromPip > 0) {
      player.pause();
      setIsLiveEdge("1");
      //afterProgress.current = currentTimeIsFromPip;
      setTimeout(() => {
        player.ready(() => {
          console.log("ready");
          isLoadedPltv.current = true;
          //seekToSelectedProgress(currentTimeIsFromPip);
          player.currentTime(currentTimeIsFromPip);
          setCurrentTimeIsFromPip(null);
          player.play();
        });
      }, 1000);
    }
  }, [currentTimeIsFromPip]);

  useEffect(() => {
    rewindBackTvRef.current = rewindBackTv;
  }, [rewindBackTv]);

  useEffect(() => {
    offTimeUpdate();
    restartTvRef.current = restartTv;
    if (isLoadedPltv.current || restartTv > 0) {
      seekToSelectedProgress(0);
      restartTvRef.current = 0;
      console.log("restart bar", restartTv);
    }
  }, [restartTv]);

  useEffect(() => {
    return () => {
      player && player.off("loadedmetadata");
      timeUpdateIntervalId.current && offTimeUpdate();
    };
  }, []);

  const myLogger = (key, val = "") => {
    console.log(`playerBar ${key}: `, val);
  };

  return (
    <div className="rc-slider-wrapper" ref={sliderRef} id="rc-slider">
      <SliderWithTooltip
        min={0}
        max={1000}
        step={1}
        value={progress}
        onChange={(value) => {
          setProgress(value);
        }}
        onAfterChange={(val) => {
          afterProgress.current = val;
          if (isPltv || val > progressRef.current) {
            seekToSelectedProgress(val);
          } else {
            seekToSelectedProgress(val);
            setIsLiveEdge("1");
          }
        }}
        onBeforeChange={() => {
          offTimeUpdate();
        }}
        tipFormatter={() => time}
        disabled={!isTimeshiftEnable}
      />
    </div>
  );
};

export default PlayerBar;
