import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getProfilesApi,
  loginWithProfile,
  checkProfilePassword,
  callLogout,
  checkProfilePin
} from "../../api/user/user";
import ProfileItem from "../profileItem/ProfileItem";
import localizationStrings from "../../statics/localization";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useParams } from "react-router-dom";
import Notification from "../notification/Notification";
import { setCookieApi } from "../../api/config/configApi";
import { sendAliveReport } from "../../utils/reporting/customReporting";
import {
  defaultProfileAvatar,
  getColectAgent,
  getWs,
  setWhereFrom
} from "../../statics/config";
import mainLogo from "../../assets/logo/Logo.png";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import { reportingEvent } from "../../statics/reportEventId";
import moment from "moment";
import { getMainboards } from "../../api/vod/vod";
import { isFirefox } from "react-device-detect";
import { firefoxPinSecret } from "../../utils/firefoxPinSecret";
import openKillSessionPopup from "../../utils/openKillSessionPopup";

const ProfileList = ({ isFromProfileLogin }) => {
  const [showPinCode, setShowPinCode] = useState(false);
  const [showEditPinCode, setShowEditPinCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [pinCode, setPinCode] = useState("");
  const [avatar, setAvatar] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showProfileDetail, setShowProfileDetail] = useState(false);
  const [closeClicked, setCloseClicked] = useState(0);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(false);
  const [pinText, setPinText] = useState("");
  const [i, setI] = useState(0);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const {
    hasLoggedIn,
    profile,
    setProfile,
    adminProfile,
    userFrameConfig,
    userInfo,
    setSelectedChannel,
    setAllProfiles,
    allProfiles,
    handleLogout
  } = useContext(SessionContext);
  let history = useHistory();
  const timeoutRef = useRef(null);
  const addButton = {
    id: -1,
    name: localizationStrings.profile.addProfile
  };
  let { id } = useParams(); //profilecode if click on navbar
  const isAvatarOpened = useRef(false);

  let currentProfile = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    // console.log("deneme",id,allProfiles);

    if (id && id !== "all") {
      let selectedProfile = allProfiles.find((item) => item.id == id);
      console.log(selectedProfile);

      if (selectedProfile) {
        onProfileSelection(selectedProfile);
      }
    }
  }, []);

  useEffect(() => {
    !hasLoggedIn && history.replace("/login");
  }, []);

  const getBackgroundImage = async () => {
    const { data } = await getMainboards(6);
    if (data.returncode === "0") {
      let obj = data.data[0];
      let poster = obj?.posterpath + obj?.phonebanner;
      setBackgroundImageUrl(poster);
      localStorage.setItem("backgroundImageUrl", poster);
    }
  };

  const getProfiles = async () => {
    if (allProfiles?.length > 0 && isFromProfileLogin) return;
    const { data } = await getProfilesApi();

    if (!!data) {
      setAllProfiles(data);
    }
  };

  const onProfileSelection = (item) => {
    setSelectedProfile(item);
    item.posterimage = `${process.env.PUBLIC_URL}/profilePosters/${item.posterName}.png`;
    setAvatar(item.posterimage);
    if (!item?.hasPin) {
      setShowLoading(true);
      setShowCloseIcon(true);
      setShowPinCode(true);
      timeoutRef.current = setTimeout(() => {
        loginProfile(item);
      }, 3000);
    } else {
      setShowPinCode(true);
    }
  };

  const goSettings = (item) => {
    setSelectedProfile(item);
    item.posterimage = `${process.env.PUBLIC_URL}/profilePosters/${item.posterName}.png`;
    setAvatar(item.posterimage);
    if (!item?.hasPin || (item?.kid && currentProfile?.main)) {
      history.push(`/profile/${item.id}`);
    } else {
      setShowEditPinCode(true);
    }
  };

  const loginProfile = async (item) => {
    //setShowLoading(true);
    //setShowPinCode(true);
    setShowCloseIcon(false);
    const { data } = await loginWithProfile(item.id, item.pin);

    if (!!data && data?.token) {
      setSelectedChannel(null);
      localStorage.setItem("profileToken", data.token);
      localStorage.setItem("profile", JSON.stringify(item));
      localStorage.setItem("profiles", JSON.stringify(allProfiles));
      setProfile(item);
      history.push("/dashboard/home");
    }
  };

  const checkPinCode = async () => {
    setShowLoading(true);
    setShowCloseIcon(true);

    const { data } = await checkProfilePin(selectedProfile?.id, pinCode);

    if (data) {
      if (showEditPinCode) {
        sessionStorage.setItem("pin", pinCode);
        history.push(`/profile/${selectedProfile?.id}`);
      } else {
        if (!isFromProfileLogin) {
          timeoutRef.current = setTimeout(() => {
            loginProfile(selectedProfile);
            if (!!document.pictureInPictureElement) {
              setWhereFrom("");
              document.exitPictureInPicture();
            }
          }, 3000);
        } else {
          loginProfile(selectedProfile);
        }
      }
    } else {
      setShowLoading(false);
      setShowCloseIcon(false);
      if (!data) {
        setShowNotification(true);
        setNotificationText(localizationStrings.wrongPinText);
      }
    }
  };

  const logout = async () => {
    const { data } = await callLogout(userInfo?.usercode);
    if (data.ReturnCode === "0") {
      let collectAgent = getColectAgent();
      if (collectAgent) {
        let eventtime = moment(
          moment().valueOf() - parseInt(localStorage.getItem("timeDiff"))
        )
          .utc()
          .format("yyyyMMDDHHmmss");

        collectAgent.logLogout({
          eventid: reportingEvent.E_LOGIN_OUT,
          eventtime: eventtime, //login triggering time (String UTC time, eg: "20210326101813"
          actiontime: eventtime,
          result: "0",
          logintype: 0 //login type 0: Account login, 1: Mobile login, 2: Mailbox login, 3: Loginname login (String, eg: "3."
        });
      }
      sendAliveReport();
      let ws = getWs();
      if (ws?.readyState === WebSocket.OPEN) {
        let prams = {
          TransactionID: JSON.stringify(new Date().getTime()),
          DeviceID: localStorage.getItem("deviceid"),
          UserID: userInfo?.usercode,
          TerminalFlag: 4,
          UserToken: userInfo?.usertoken,
          DKey: 0,
          Timestamp: JSON.stringify(new Date().getTime()),
          MsgType: "terminaldelreq"
        };
        ws.send(JSON.stringify(prams));
      }

      if (parseInt(localStorage.getItem("logintype")) == 4) {
        localStorage.clear();
        openKillSessionPopup(userFrameConfig?.easHttpsDomain);
        //window.location.replace(fastLoginSessionKillUrl);
      } else {
        localStorage.clear();
        window.location.replace(userFrameConfig?.easHttpsDomain);
      }
    }
  };

  const removeCookieApi = async (key, value) => {
    const { data } = await setCookieApi(key, value);
    if (data.returncode === "0") {
      if (key === "upd") {
        removeCookieApi("uid", "");
      } else if (key === "uid") {
        logout();
      }
    }
  };

  const renderedItems = allProfiles.map((item) => {
    return (
      <ProfileItem
        key={item.id}
        item={item}
        onSelection={onProfileSelection}
        currentProfile={currentProfile}
        isFromProfileLogin={isFromProfileLogin}
        count={allProfiles.length + 1}
        onEditClick={goSettings}
      />
    );
  });

  useEffect(() => {
    // getBackgroundImage();
    getProfiles();
  }, []);

  return (
    <section
      className="profile-container"
      style={{ background: `url(${backgroundImageUrl}) center center` }}
    >
      <div className="row">
        <div className="logo-wrapper" style={{}}>
          <img src={mainLogo} alt="atlas logo" className="atlas-logo" />
        </div>
        {(!isFromProfileLogin && !showLoading) ||
        (isFromProfileLogin && showPinCode) ? (
          <div
            className="close"
            onClick={() => {
              if (isFromProfileLogin) {
                setShowPinCode(false);
              } else if (showProfileDetail) {
                if (isAvatarOpened.current) {
                  let count = closeClicked;
                  setCloseClicked(++count);
                } else {
                  setShowProfileDetail(false);
                }
              } else {
                history.push("/dashboard");
              }
            }}
          >
            <PopupCloseIcon />
          </div>
        ) : null}
      </div>
      {showPinCode ? (
        <div className="pin-code-wrapper">
          <div className={`text-logging ${showLoading ? "show" : ""}`}>
            {localizationStrings.profile.logging}
          </div>
          <div className="profile-item" style={{ marginBottom: "5px" }}>
            <div className="profile-avatar">
              <img
                src={avatar}
                onError={() => setAvatar(defaultProfileAvatar)}
              />
              {showLoading ? <div className="profile-loading"></div> : null}
            </div>
            <div className="profile-name">{selectedProfile.name}</div>
          </div>

          {!showLoading ? (
            <div style={{ position: "relative", width: "100%" }}>
              <div style={{ position: "relative", width: "100%" }}>
                <form
                  id="profileListForm"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      checkPinCode();
                    }
                  }}
                >
                  {!isFirefox ? (
                    <input
                      style={{ width: "100%" }}
                      className={showPassword ? "" : "profile-input pin"}
                      autoComplete="off"
                      autoFocus={true}
                      name="profilePin"
                      id="profileListPin2"
                      type="number"
                      placeholder={localizationStrings.login.pin}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                        setPinCode(e.target.value);
                      }}
                    />
                  ) : (
                    <input
                      style={{ width: "100%" }}
                      className={
                        showPassword ? "profile-input" : "profile-input"
                      }
                      autoComplete="off"
                      onMouseUp={(e) => {
                        let [pin, i] = firefoxPinSecret(null, e);
                        setI(i);
                        setPinText(pin.replace(/[0-9,•]/g, "•"));
                        setPinCode(pin);
                      }}
                      onKeyDown={(e) => {
                        let [pin, i] = firefoxPinSecret(e, null);
                        setI(i);
                        setPinText(pin.replace(/[0-9,•]/g, "•"));
                        setPinCode(pin);
                      }}
                      autoFocus={true}
                      name="profilePin"
                      id="profileListPin2"
                      type="text"
                      placeholder={localizationStrings.login.password}
                      onChange={(e) => {
                        e.target.value = pinText;
                        e.target.selectionEnd = i;
                        e.target.selectionStart = i;
                      }}
                    />
                  )}
                  <div
                    className="yellow-button"
                    style={{ marginTop: "2.6vw" }}
                    onClick={() => {
                      checkPinCode();
                    }}
                  >
                    {localizationStrings.signIn}
                  </div>
                </form>
              </div>
            </div>
          ) : showCloseIcon ? (
            <div
              className="close"
              onClick={() => {
                timeoutRef.current && clearTimeout(timeoutRef.current);
                setShowLoading(false);
                setShowPinCode(false);
                setShowCloseIcon(false);
              }}
            >
              <PopupCloseIcon />
            </div>
          ) : null}
        </div>
      ) : showEditPinCode ? (
        <div className="pin-code-wrapper">
          <div style={{ position: "relative", width: "100%" }}>
            <div className="text">
              {localizationStrings.enterProfilePassword}
            </div>
            <form
              id="profileListForm"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  checkPinCode();
                }
              }}
            >
              {!isFirefox ? (
                <input
                  style={{ width: "100%" }}
                  className="profile-input pin"
                  autoComplete="off"
                  autoFocus={true}
                  name="profilePin"
                  id="profileListPin"
                  type="number"
                  placeholder={localizationStrings.login.pin}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                    setPinCode(e.target.value);
                  }}
                />
              ) : (
                <input
                  style={{ width: "100%" }}
                  className="profile-input"
                  autoComplete="off"
                  autoFocus={true}
                  name="profilePin"
                  id="profileListPin"
                  type="text"
                  onMouseUp={(e) => {
                    let [pin, i] = firefoxPinSecret(null, e);
                    setI(i);
                    setPinText(pin.replace(/[0-9,•]/g, "•"));
                    setPinCode(pin);
                  }}
                  onKeyDown={(e) => {
                    let [pin, i] = firefoxPinSecret(e, null);
                    setI(i);
                    setPinText(pin.replace(/[0-9,•]/g, "•"));
                    setPinCode(pin);
                  }}
                  placeholder={localizationStrings.login.password}
                  onChange={(e) => {
                    e.target.value = pinText;
                    e.target.selectionEnd = i;
                    e.target.selectionStart = i;
                  }}
                />
              )}
              <div
                className="yellow-button"
                style={{ marginTop: "2.6vw" }}
                onClick={() => checkPinCode()}
              >
                {localizationStrings.continue}
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="profile-wrapper">
          <div className="text">
            {isFromProfileLogin
              ? localizationStrings.profileLoginText
              : localizationStrings.profileManagement}
          </div>
          <div className="profile-items-box">
            {renderedItems}
            {!isFromProfileLogin && !currentProfile.kid ? (
              <ProfileItem
                item={addButton}
                onSelection={() => {
                  history.push("/profile/new");
                }}
                count={allProfiles?.length + 1}
              />
            ) : null}
          </div>
          {isFromProfileLogin ? (
            <div
              className="red-button"
              onClick={() => {
                handleLogout();
                history.replace("/login");
              }}
            >
              {localizationStrings.logout2}
            </div>
          ) : null}
        </div>
      )}
      {showNotification ? (
        <Notification
          text={notificationText}
          confirmText={localizationStrings.close}
          onClickConfirm={() => setShowNotification(false)}
        />
      ) : null}
      <div className="profile-gradient"></div>
      <div className="profile-gradient-2"></div>
    </section>
  );
};

export default ProfileList;
