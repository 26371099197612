import React, { useContext, useEffect, useRef, useState } from "react";
import localizationStrings from "../../statics/localization";
import { SessionContext } from "../../context/SessionContext";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import RtukAgelcon from "../../assets/icons/svg/RtukAgeIcon";
import Notification from "../notification";
import {
  changeProfileParentalLevel,
  parentalControlApi,
  getUserInitialInfo,
  modifyProfile,
  getProfilesApi
} from "../../api/user/user";
import { useHistory } from "react-router-dom";
import PasswordModal from "../passwordModal";
import UnselectIcon from "../../assets/icons/svg/UnselectIcon";
import SelectedIcon from "../../assets/icons/svg/SelectedIcon";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import BigLockIcon from "../../assets/icons/svg/BigLockIcon";

const ParentalControl = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [showNoPinNotification, setShowNoPinNotification] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [userInfo, setUserInfo_] = useState(null);
  const [askAdminPin, setAskAdminPin] = useState(false);
  const {
    profile,
    adminProfile,
    setUserInfo,
    userFrameConfig,
    setProfile,
    setAdminProfile
  } = useContext(SessionContext);
  const selectedUserLevel = useRef(null);
  const history = useHistory();

  let currentProfile = JSON.parse(localStorage.getItem("profile"));

  const parentalContols = [
    {
      userlevel: "G",
      icon: <RtukGeneralIcon />,
      description: localizationStrings.parentalControlText.general
    },
    {
      userlevel: "7+",
      icon: <RtukSevenAgeIcon />,
      description: localizationStrings.parentalControlText.sevenPlus
    },
    {
      userlevel: "13+",
      icon: <RtukThirteenAgeIcon />,
      description: localizationStrings.parentalControlText.thirteenPlus
    },
    {
      userlevel: "18+",
      icon: <RtukAgelcon />,
      description: localizationStrings.parentalControlText.allAge
    }
  ];

  const clickConfirm = async () => {
    setShowNotification(false);
    const data = await parentalControlApi(selectedUserLevel.current);
    const profilesData = await getProfilesApi();
    if (profilesData.status === 200) {
      let profiles = profilesData.data;
      let item = profiles.find((el) => el.id === profile.id);
      item && setProfile(item);
      if (currentProfile?.main) {
        setAdminProfile(item);
      }
      localStorage.setItem("profiles", JSON.stringify(profiles));
      localStorage.setItem("profile", JSON.stringify(item));

      if (data.status === 200 && profilesData.status === 200) {
        history.push(`/dashboard/settings`);
        window.location.reload();
      } else {
        alert(data.errormsg);
      }
    }
  };

  const clickNotConfirm = () => {
    setShowNotification(false);
  };

  const goProfileSettings = () => {
    setShowNoPinNotification(false);
    let profileCode;
    if (profile?.profileroleid === userFrameConfig.Kids_Profile_Id) {
      profileCode = adminProfile.profilecode;
    } else {
      profileCode = profile.profilecode;
    }
    history.push(`/profile/${profileCode}?page=1`);
  };

  const getUserInfo = async () => {
    const { data } = await getUserInitialInfo();
    if (data.returncode === "0") {
      setUserInfo_(data);
      setUserInfo(data);
    }
  };

  const renderedItems = parentalContols.map((item, index) => {
    let select;

    if (item.userlevel === profile?.majurity) {
      select = <SelectedIcon />;
    } else if (item.userlevel !== profile?.majurity) {
      select = <UnselectIcon />;
    }

    return (
      <div
        key={item.userlevel}
        onClick={() => {
          selectedUserLevel.current = item.userlevel.replace(/\+/g, "");
          setShowNotification(true);
        }}
        className="parentalcontrol-card-wrapper"
      >
        <div className="parentalcontrol-card">
          <div className="parentalcontrol-card-body">
            <div className="dotsvg">
              <div className="icon">{select}</div>
            </div>

            <div className="parentalcontrol-card-icon">{item.icon}</div>
            <p className="parentalcontrol-card-text">{item?.description}</p>
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (adminProfile && profile && userFrameConfig) {
      if (profile?.profileroleid !== userFrameConfig.Kids_Profile_Id) {
        if (profile.havepwd === "1") {
          setShowPasswordModal(true);
          setAskAdminPin(false);
        } else {
          setShowNoPinNotification(true);
        }
      } else {
        setAskAdminPin(true);

        if (adminProfile.havepwd === "1") {
          setShowPasswordModal(true);
        } else {
          setShowNoPinNotification(true);
        }
      }
    }
  }, [adminProfile, profile, userFrameConfig]);

  return (
    <div className="settings-container">
      <div
        className="settings-icon"
        onClick={() => {
          history.push(`/dashboard/settings`);
        }}
      >
        <ReturnIcon />
        <label>{localizationStrings.parentalControl}</label>
      </div>
      <div className="parental-control-container">{renderedItems}</div>
      {showNotification ? (
        <Notification
          text={localizationStrings.parentalControlText.changeText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.no}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
        />
      ) : null}

      {showPasswordModal ? (
        <Notification
          text={
            profile?.profileroleid === userFrameConfig?.Kids_Profile_Id
              ? localizationStrings.askAdminPin
              : localizationStrings.askProfilePin
          }
          confirmText={localizationStrings.enter}
          onClickConfirm={() => {}}
          showCloseButton={true}
          onClickNotConfirm={() => {
            setShowPasswordModal(false);
            history.push(`/dashboard/settings`);
          }}
          isPassword={true}
          icon={<BigLockIcon />}
          sendResult={(val) => {
            if (!val) {
              history.push(`/dashboard/settings`);
            }
            setShowPasswordModal(false);
          }}
          profileCode={
            askAdminPin ? adminProfile.profilecode : profile.profilecode
          }
        />
      ) : null}
      {showNoPinNotification ? (
        <Notification
          text={localizationStrings.createProfilePinCodeText}
          confirmText={localizationStrings.createProfilePinCode}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={goProfileSettings}
          onClickNotConfirm={() => {
            setShowNoPinNotification(false);
            history.push(`/dashboard/settings`);
          }}
        />
      ) : null}
    </div>
  );
};

export default ParentalControl;
