import React, { useEffect, useState } from "react";
import LiveIcon from "../../../assets/icons/svg/LiveIcon";
import localizationStrings from "../../../statics/localization";

const BackLiveButton = ({ setIsLiveEdge, isProgram, player }) => {
  // const [isLive, setIsLive] = useState(true);

  // const checkLiveEdge = () => {
  //   if (!player || player.duration() !== Infinity) return; // Yayın canlı değilse kontrol yapma
  //   const liveDifference =
  //     player.getChild("LiveTracker").liveCurrentTime() - player.currentTime();
  //   console.log(
  //     "live diff",
  //     player.getChild("LiveTracker").liveCurrentTime() - player.currentTime()
  //   );

  //   setIsLiveEdge(liveDifference <= 15); // 2 saniye eşik değeri
  // };

  const handleReturnToLive = () => {
    const liveCurrentTime = player.getChild("LiveTracker").liveCurrentTime();
    player.currentTime(liveCurrentTime); // Kullanıcıyı canlı uca taşı
    console.log("Kullanıcı canlı yayına döndü.");
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     checkLiveEdge();
  //   }, 3000);

  //   return () => clearInterval(interval); // Component unmount olduğunda interval'i temizle
  // }, [player]);
  return (
    <button
      className="control-button back-live"
      //style={{ display: isLive ? "none" : "block" }}
      onClick={() => {
        handleReturnToLive();
        if (isProgram) {
          setIsLiveEdge("2");
        } else {
          setIsLiveEdge("0");
        }
      }}
    >
      <div style={{ display: "flex", fontSize: "0.47vw" }}>
        <LiveIcon />
      </div>
      <span>{localizationStrings.goLive}</span>
    </button>
  );
};

export default BackLiveButton;
