import React, { useMemo } from "react";
import BackSecondsIcon from "../../../assets/icons/svg/BackSecondsIcon";
import localizationStrings from "../../../statics/localization";
import moment from "moment";

const RewindBackButton = ({
  player,
  setIsLiveEdge,
  isLive,
  isTimeshiftEnable,
  isPltv,
  rewindBackTv,
  isFullscreen,
  program
}) => {
  let secsToBack = 15;

  const isNearBeginTime = () => {
    let now = new Date().getTime() / 1000;
    let currentTime = player?.currentTime();
    let liveCurrentTime = player?.getChild("LiveTracker").liveCurrentTime();
    let beginTime =
      moment(program?.begintime, "YYYY.MM.DD HH:mm").valueOf() / 1000;
    let progress = now - (liveCurrentTime - currentTime) - beginTime;
    return progress;
  };

  useMemo(() => {
    if (isPltv) {
      player.on("play", () => {
        setTimeout(() => {
          player.currentTime(player.currentTime() - secsToBack);
        }, 200);
        player.off("play");
      });
    }
  }, [isPltv]);

  return (
    <button
      className="control-button"
      style={{
        cursor: isTimeshiftEnable ? "pointer" : "not-allowed",
        marginLeft: isFullscreen ? "1.2vw" : null
      }}
      title={localizationStrings.playerOptions.secsToBack}
      onClick={() => {
        if (isLive) {
          if (isTimeshiftEnable) {
            if (isPltv) {
              player.currentTime(player.currentTime() - secsToBack);
            } else {
              player.currentTime(player.currentTime() - secsToBack);
              setIsLiveEdge("1");
              rewindBackTv(Math.random() * 1000);
            }
          }
        } else {
          player.currentTime(player.currentTime() - secsToBack);
        }
      }}
    >
      <BackSecondsIcon />
    </button>
  );
};

export default RewindBackButton;
