import React, { useContext, useEffect, useRef, useState } from "react";
import {
  getMultiProgramData,
  getLtvChannels,
  getFavoriteChannels,
  getUserMyTvChannel,
  getChannels
} from "../../api/ltv/ltv";
import localizationStrings from "../../statics/localization";
import FilterAllIcon from "../../assets/icons/svg/FilterAllIcon";
import {
  getColumnList,
  getUserLastChannelData
} from "../../api/config/configApi";
import moment from "moment";
import { SessionContext } from "../../context/SessionContext";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalPortal from "../../components/modalPortal";
import ProgramDetail from "../../components/programDetail";
import ModalRouting from "../../components/modalRouting/ModalRouting";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import BannerAd from "../../components/bannerAd/BannerAd";
import { tvGuideBannerAdConfig } from "../../statics/config";
import TvGuideIcon from "../../assets/icons/svg/TvGuideIcon";
import SearchCloseIcon from "../../assets/icons/svg/SearchCloseIcon";
import ChannelListWidget from "../../components/channelListWidget";
import TvGuideRow from "../../components/tvGuideRow/TvGuideRow";
import AngleRightIcon from "../../assets/icons/svg/AngleRightIcon";
import SearchIcon from "../../assets/icons/svg/SearchIcon";
import { channelGenres } from "../../api/mockup/mockup";

const TvGuide = () => {
  const [data, setData] = useState([]);
  const [channels, setChannels] = useState([]);
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [dates, setDates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const channelCodes = useRef("");
  const [term, setTerm] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [renderedChannels, setRenderedChannels] = useState([]);
  const {
    userFrameConfig,
    setPath,
    userInfo,
    isChildProfile,
    setSelectedCutvProgram,
    selectedChannel,
    channelItems,
    setChannelItems,
    adminProfile,
    profile,
    setSelectedChannel,
    setPlayUrl,
    playUrl,
    hasLoggedIn
  } = useContext(SessionContext);
  const location = useLocation();
  let { path } = useRouteMatch();
  const slidings = [];
  let endOfDay;
  let startOfDay;
  const inputRef = useRef(null);
  const filterTimeoutId = useRef(null);
  const pageRef = useRef(0);
  let count = 15;
  let favoriteColumn = {
    columncode: "fav-channel",
    columnname: localizationStrings.favChannel
  };
  let bannerAdress = "/22269014526/OTT_Desktop_Web_EPG_970x90";
  const history = useHistory();
  const channelListEl = useRef(null);
  const tvGuideEl = useRef(null);
  const channelsRef = useRef([]);
  const dataRef = useRef([]);
  const allChannels = useRef([]);
  const filteredChannels = useRef([]);
  const programRef = useRef(null);
  const isCurrentRef = useRef(null);
  const scrollWidth = window.innerWidth * 0.26 + 12; // 12 is margin right

  const getChannelItems = async () => {
    if (!channelItems || channelItems.length === 0) {
      const data = await getChannels();
      if (data.data) {
        let channels = data.data;
        channels = channels.sort((a, b) => {
          return parseInt(a?.no) - parseInt(b?.no);
        });

        allChannels.current = channels;
        setChannelItems(channels);
      }
    } else {
      allChannels.current = channelItems;
    }

    //const filtered = allchannelsitems.filter(item => item.genre === selectedGenre);
    const filtered =
      selectedGenre?.name !== "Tüm Kanallar"
        ? selectedGenre?.name === "Favori Listem"
          ? allChannels.current.filter((item) =>
              item.interactions.includes("CHANNEL_FAV")
            )
          : allChannels.current.filter(
              (item) => item.genre === selectedGenre?.name
            )
        : allChannels.current;
    filteredChannels.current = filtered;

    getNext();
    // let columnCode_ = isChildProfile
    //   ? userFrameConfig?.ChildMode_TV_Column_All
    //   : userFrameConfig?.TV_Column_All;
    // if (channelItems?.length && selectedGenre?.columncode === columnCode_) {
    //   allChannels.current = channelItems;
    //   getNext();
    // } else {
    //   const data = await getLtvChannels(selectedGenre?.columncode, 1, 500);
    //   if (data.data.returncode === "0") {
    //     let channelData = data.data.data;
    //     allChannels.current = channelData.sort((a, b) => {
    //       return parseInt(a?.usermixno) - parseInt(b?.usermixno);
    //     });
    //     getNext();
    //   }
    // }
  };

  const getNext = () => {
    let channelData = filteredChannels?.current.slice(
      pageRef.current * count,
      pageRef.current * count + count
    );

    if (filteredChannels?.current && filteredChannels.current?.length) {
      channelsRef.current = channelsRef.current.concat(channelData);
      setRenderedChannels(channelsRef.current);
      pageRef.current++;
    } else {
      channelCodes.current = "";
      channelsRef.current = [];
      setRenderedChannels(channelsRef.current);
      setData([]);
      setChannels([]);
    }
  };

  const filterChannels = (input) => {
    if (input.length) {
      let filtered = filteredChannels.current.filter((item) => {
        if (!item.name || !input) {
          return false;
        }

        const channelNameLowerCase = item.name.toLocaleLowerCase("tr-TR");
        const inputLowerCase = input.toLocaleLowerCase("tr-TR");

        return (
          channelNameLowerCase.startsWith(inputLowerCase) ||
          channelNameLowerCase.includes(inputLowerCase)
        );
      });

      setRenderedChannels(filtered);
    } else {
      setRenderedChannels(channelsRef.current);
    }
  };

  const getUserFavoriteChannels = async () => {
    const { data } = await getFavoriteChannels(userFrameConfig?.TV_Column);
    if (data.returncode === "0") {
      let channelData = data.data;
      allChannels.current = channelData;
      getNext();
    }
  };

  const getGenres = async () => {
    //const data = await getColumnList(userFrameConfig.TV_Column);
    const data = channelGenres;
    setGenres(channelGenres);
    setSelectedGenre(channelGenres[0]);
    //const _data = await getMultiProgramData(channelCodes, startOfDay, endOfDay);

    // if (data.data.returncode === "0") {
    //   let arr = data.data.data;
    //   let colCode = isChildProfile
    //     ? userFrameConfig.ChildMode_TV_Column_All
    //     : userFrameConfig.TV_Column_All;

    //   setSelectedGenre(arr.find((item) => item.columncode === colCode));
    //   let allColumnIndex = arr.findIndex((item) => item.columncode === colCode);

    //   let allColumn = arr[allColumnIndex];
    //   arr.splice(allColumnIndex, 1);

    //   if (arr.length) {
    //     arr.splice(0, 0, allColumn);
    //     if (localStorage.getItem("usertype") === "1") {
    //       arr.splice(1, 0, favoriteColumn);
    //     }
    //   }
    //   setGenres(arr);
    // }
  };

  const onScroll = (event) => {
    tvGuideEl.current.style.scrollBehavior = "unset";
    tvGuideEl.current.scrollTop = channelListEl.current.scrollTop;
  };
  const guideScroll = (event) => {
    channelListEl.current.style.scrollBehavior = "unset";
    channelListEl.current.scrollTop = tvGuideEl.current.scrollTop;
  };

  const slideTvGuide = (direction) => {
    tvGuideEl.current.style.scrollBehavior = "smooth";

    if (direction === "right") {
      tvGuideEl.current.scrollLeft += scrollWidth;
    } else if (direction === "left") {
      tvGuideEl.current.scrollLeft -= scrollWidth;
    } else if (direction === "begin") {
      tvGuideEl.current.scrollLeft = 0;
    }
  };

  const channelSelection = (item, isRefresh = false) => {
    history.push(`/dashboard/livetv/${item.id}`);
    if (isRefresh) {
      setSelectedChannel((prevState) => {
        let newObj = {};
        Object.assign(newObj, prevState);
        return newObj;
      });
    } else {
      setSelectedChannel(item);
    }
    setPath(`/dashboard/livetv/${item.id}`);
  };

  const findSelectedChannel = () => {
    if (typeof selectedChannel?.id !== "number") return;
    let selectedChannelIndex = channelItems.findIndex(
      (item) => item?.id === selectedChannel?.id
    );
    if (selectedChannelIndex === -1) return;
    for (
      let index = pageRef.current;
      index < Math.ceil((selectedChannelIndex + 1) / count);
      index++
    ) {
      getNext();
    }
  };

  useEffect(() => {
    // if (userFrameConfig?.TV_Column) {
    //   if (isChildProfile) {
    //     let arr = [
    //       {
    //         columnname: localizationStrings.child,
    //         columncode: userFrameConfig?.ChildMode_TV_Column_All
    //       },
    //       {
    //         columncode: "fav-channel",
    //         columnname: localizationStrings.favChannel
    //       }
    //     ];
    //     setSelectedGenre({
    //       columnname: localizationStrings.child,
    //       columncode: userFrameConfig?.ChildMode_TV_Column_All
    //     });
    //     setGenres(arr);
    //   } else {
    //     getGenres();
    //   }
    //   calcDatesForOneWeek();
    //   setPath(path);
    // }
    if (isChildProfile) {
      let arr = [
        {
          columnname: localizationStrings.child,
          columncode: userFrameConfig?.ChildMode_TV_Column_All
        },
        {
          columncode: "fav-channel",
          columnname: localizationStrings.favChannel
        }
      ];
      setSelectedGenre({
        columnname: localizationStrings.child,
        columncode: userFrameConfig?.ChildMode_TV_Column_All
      });
      setGenres(arr);
    } else {
      getGenres();
    }
    calcDatesForOneWeek();
    setPath(path);
  }, []); // userFrameConfig

  useEffect(() => {
    channelsRef.current = [];
    dataRef.current = [];
    // tvGuideEl.current.scrollTop = 0;
    // channelListEl.current.scrollTop = 0;
    pageRef.current = 0;
    getChannelItems();
    // if (selectedGenre?.columncode) {
    //   channelsRef.current = [];
    //   dataRef.current = [];
    //   tvGuideEl.current.scrollTop = 0;
    //   channelListEl.current.scrollTop = 0;
    //   pageRef.current = 0;
    //   if (selectedGenre?.columncode !== "fav-channel") {
    //     getChannels();
    //   } else {
    //     getUserFavoriteChannels();
    //   }
    // }
  }, [selectedGenre, selectedDate]);

  useEffect(() => {
    channelListEl.current = document.getElementById(
      "vertical-channel-list-channellist-3"
    );
    tvGuideEl.current = document.getElementById("tvguideWrapper");
    channelListEl.current.addEventListener("scroll", onScroll);
    //let guideTimeout = setTimeout(() => {
    tvGuideEl.current.addEventListener("scroll", guideScroll);
    //}, 5000);
    return () => {
      //guideTimeout && clearTimeout(guideTimeout);
      channelListEl.current.removeEventListener("scroll", onScroll);
      tvGuideEl.current.removeEventListener("scroll", guideScroll);
    };
  }, []);

  useEffect(() => {
    if (channelItems && channelItems.length > 0) {
      setTimeout(() => {
        findSelectedChannel();
      }, 100);
    }
  }, [channelItems]);

  const renderedItems = renderedChannels?.map((item, index) => {
    return (
      <TvGuideRow
        key={index}
        channelid={item.id}
        date={selectedDate}
        selectedChannel={selectedChannel}
        onClickProgram={(val, isCurrent) => {
          programRef.current = val;
          isCurrentRef.current = isCurrent;
          console.log(programRef.current, val, isCurrent);
          setShowModal(true);
        }}
      />
    );
  });

  const calcDatesForOneWeek = () => {
    let dates = [];

    for (let i = -7; i < 7; ++i) {
      dates.push({
        id: i,
        name: moment()
          .add(i, "days")
          .calendar(null, {
            sameDay: `[${localizationStrings.today}]`,
            nextDay: `[${localizationStrings.tomorrow}]`,
            lastDay: `[${localizationStrings.yesterday}]`,
            nextWeek: "Do MMMM",
            lastWeek: "Do MMMM",
            sameElse: "Do MMMM"
          })
      });
    }
    setSelectedDate(dates[7]);
    setDates(dates);
  };
  const getLiveTvLastChannel = async () => {
    const { data } = await getUserLastChannelData();
    let url = ``;
    if (data?.returncode === "0" && data?.datavalue !== "") {
      url = `/dashboard/livetv/${data?.datavalue}`;
    } else if (userFrameConfig?.Start_Channel) {
      url = `/dashboard/livetv/start`;
    } else {
      url = `/dashboard/livetv/all`;
    }
    history.push(url);
  };

  return (
    <div className="container">
      <div className="filter-row">
        <div className="filter-box">
          <div
            className={`tv-filter ${isFilterOpen ? "selected" : ""}`}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <div className="label-text">{selectedGenre?.name}</div>
            <div className="filter-icon">
              <FilterAllIcon />
            </div>
          </div>

          <div
            className={`tv-search ${isSearchOpen ? "open" : ""}`}
            onClick={() => {
              if (!isSearchOpen) {
                setIsSearchOpen(true);
                setTimeout(() => {
                  inputRef.current.focus();
                }, 100);
              }
            }}
          >
            <form
              autoComplete="off"
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                id="tv-search"
                name="tv-search"
                type="text"
                value={term}
                ref={inputRef}
                onChange={(e) => {
                  if (filterTimeoutId.current) {
                    clearTimeout(filterTimeoutId.current);
                  }
                  filterTimeoutId.current = setTimeout(() => {
                    filterChannels(e.target.value);
                  }, 500);
                  setTerm(e.target.value);
                }}
                placeholder={localizationStrings.searchChannel}
              />
              <span
                onClick={() => {
                  if (isSearchOpen) {
                    setTerm("");
                    filterChannels("");
                    setIsSearchOpen(false);
                  }
                }}
              >
                {isSearchOpen ? <SearchCloseIcon /> : <SearchIcon />}
              </span>
            </form>
          </div>

          <div
            className={`tv-filter date-filter ${
              showDateFilter ? "selected" : ""
            }`}
            onClick={() => setShowDateFilter(!showDateFilter)}
          >
            <div className="label-text">{selectedDate?.name}</div>
            <div className="filter-icon">
              <FilterAllIcon />
            </div>

            <div
              className={`primary-select-box-options ${
                showDateFilter ? "show" : ""
              }`}
              style={{ top: "3.5vw", width: "17.5vw" }}
            >
              {dates.map((item) => {
                return (
                  <div
                    className="option"
                    onClick={() => {
                      setSelectedDate(item);
                      slideTvGuide("begin");
                    }}
                  >
                    <div
                      className={`option-text ${
                        selectedDate.id === item.id ? "selected" : ""
                      }`}
                      style={{
                        fontFamily: item?.id === 0 ? "SFUITextBold" : ""
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="tv-guide-nav"
            onClick={() => {
              //go live
              let url = `/dashboard/livetv/${
                selectedChannel ? selectedChannel?.id : ""
              }`;
              history.push(url);
            }}
          >
            <div className="label-text">{localizationStrings.liveTv}</div>
            <div className="icon">
              <TvGuideIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="arrow-wrapper">
        <div className="arrow-box">
          <span className="left" onClick={() => slideTvGuide("left")}>
            <AngleRightIcon />
          </span>
          <span onClick={() => slideTvGuide("right")}>
            <AngleRightIcon />
          </span>
        </div>
      </div>

      <div
        className={`channel-container ${isFilterOpen ? "extend" : ""}`}
        style={{ width: "85vw" }}
      >
        <div
          className={`channel-list-column ${isFilterOpen ? "extend" : ""}`}
          style={{ marginTop: 0 }}
        >
          <InfiniteScroll
            dataLength={renderedChannels.length}
            next={getNext}
            hasMore={true}
            scrollableTarget="vertical-channel-list-channellist-3"
            style={{ overflow: "unset", width: "100%" }}
          >
            <ChannelListWidget
              key="channellist-3"
              uKey="channellist-3"
              channels={renderedChannels}
              selectedChannel={selectedChannel}
              genres={genres}
              selectedGenre={selectedGenre}
              setSelectedGenre={(val) => {
                setSelectedGenre(val);
                setTerm("");
                setIsSearchOpen(false);
              }}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              userFrameConfig={userFrameConfig}
              adminProfile={adminProfile}
              profile={profile}
              setLockChannelCode={(code, locked) => {
                setChannels((prevState) => {
                  const newArray = Array.from(prevState);
                  let index = newArray.findIndex(
                    (item) => item.channelcode == code
                  );
                  if (index != -1) {
                    newArray[index].islocked = locked;
                  }
                  return newArray;
                });
              }}
              onSelectChannel={channelSelection}
              setSelectedCutvProgram={setSelectedCutvProgram}
            />
          </InfiniteScroll>
        </div>
        <div
          className={`channel-detail-column ${isFilterOpen ? "extend" : ""}`}
          style={{ width: "unset" }}
        >
          <div id="tvguideWrapper" className="tvguide-wrapper">
            <div className="tvguide-container">{renderedItems}</div>
          </div>
        </div>
      </div>

      {showModal ? (
        <ModalPortal viewClass="vod-modal">
          <ProgramDetail
            programData={programRef.current}
            prevueCode={programRef.current?.prevuecode}
            isCurrent={isCurrentRef.current}
            closeModal={(isCurrent) => {
              setShowModal(false);
              if (isCurrent) {
                let channel = channels?.find(
                  (item) => item.channelcode === programRef.current?.channelcode
                );
                if (channel) {
                  channelSelection(channel);
                }
              }
            }}
            channels={channels}
            fromTvGuide={true}
            setSelectedCutvProgram={setSelectedCutvProgram}
            selectedChannel={selectedChannel}
            userFrameConfig={userFrameConfig}
            userInfo={userInfo}
            location={location}
            history={history}
            setPlayUrl={setPlayUrl}
            playUrl={playUrl}
            hasLoggedIn={hasLoggedIn}
          />
        </ModalPortal>
      ) : null}
      <ModalRouting />
      {/* <div
        className={
          showBannerAd ? "advertisement-box show" : "advertisement-box hide"
        }
      >
        <BannerAd
          key="tvguide-banner-ad"
          bannerId="tv-guide-banner-ad"
          configShowAd={userFrameConfig?.showGoogleAD === "1"}
          userInfo={userInfo}
          bannerConfig={tvGuideBannerAdConfig}
          setShowBannerAd={(val) => {
            setShowBannerAd(val);
          }}
        />
      </div> */}
    </div>
  );
};

export default TvGuide;
