import React, { useContext, useEffect, useRef, useState } from "react";
import IconRender from "../../components/iconRender";
import { NavLink, useHistory, Link } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import { defaultProfileAvatar } from "../../statics/config";
import useOutsideClick from "../../utils/useOutsideClick";
import SearchIcon from "../../assets/icons/svg/SearchIcon";
import Notification from "../notification";
import { getMenuItems } from "../../api/category/categoryApi";
import mainLoadingGif from "../../assets/loading.gif";

const Navbar = () => {
  const [posterUrl, setPosterUrl] = useState("");
  const [navbarElements, setNavbarElements] = useState([]);
  const [scrollState, setScrollState] = useState("top");
  const [showOptions, setShowOptions] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { userFrameConfig, hasLoggedIn, handleLogout } =
    useContext(SessionContext);

  const settingsRef = useRef(null);
  const history = useHistory(null);

  useOutsideClick(settingsRef, () => {
    setShowOptions(false);
  });

  let listener = null;
  let currentProfile = JSON.parse(localStorage.getItem("profile"));
  const allProfiles = JSON.parse(localStorage.getItem("profiles"));

  const clickNotConfirm = () => {
    //onaylamayı tıklayın
    setShowNotification(false);
  };

  const clickedLogout = () => {
    //tıklandı çıkış
    setNotificationText(localizationStrings.logoutText);
    setShowNotification(true);
  };

  const renderedIcons = navbarElements?.map((el) => {
    return <IconRender key={el.id} text={el.name} link={el.id} align={true} />;
  });

  const renderedProfiles = allProfiles?.map((item) => {
    return item?.id != currentProfile?.id ? (
      <NavbarProfileItem key={item?.id} item={item} />
    ) : null;
  });

  const navigateHome = () => {
    debugger;
    if (localStorage.getItem("usertype") === "0") {
      // let lang = localStorage.getItem("language");
      // let deviceid = localStorage.getItem("deviceid");
      // let softLoginUrl = localStorage.getItem("loginurl");
      // let loginUrl = `${softLoginUrl}?lan=${
      //   lang ? lang : "tur"
      // }&deviceid=${deviceid}`;
      // window.location.replace(loginUrl);
      history.push(`/dashboard/home/${userFrameConfig?.Video_Column}`);
    } else if (localStorage.getItem("usertype") === "1") {
      history.push(`/dashboard/home/${userFrameConfig?.Special_For_Me_Column}`);
    }
  };

  const getNavbarItems = async () => {
    const { data } = await getMenuItems();
    data.push({ id: "livetv", name: "Live Tv" });
    setNavbarElements(data);
  };

  useEffect(() => {
    getNavbarItems();
    if (currentProfile?.kid) {
      currentProfile.photo = "kids" + currentProfile?.photo;
    }
    let profilePoster = `${process.env.PUBLIC_URL}/profilePosters/${currentProfile?.posterName}.png`;
    setPosterUrl(profilePoster);
  }, []);

  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 10) {
        if (scrollState !== "amir") {
          setScrollState("amir");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  return (
    <>
      <nav className={`navbar ${scrollState}`}>
        <div className="navbar-row">
          <div className="menu-item-wrapper">
            <NavLink
              to={`/dashboard/home/${navbarElements[0]?.id}/all`}
              style={{ animation: "none" }}
            >
              <div className="main-logo">
                {/* //onClick={() => navigateHome()} */}
                <img
                  alt="main logo"
                  src={require("../../assets/logo/Logo.png")}
                  style={{ maxHeight: "50px" }}
                ></img>
              </div>
            </NavLink>

            {renderedIcons}
          </div>
          <div className="buttons-wrapper">
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div className="search-field">
                <div
                  onClick={() => {
                    history.push("/dashboard/search");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    zIndex: "4"
                  }}
                >
                  <SearchIcon />
                </div>
              </div>
            </div>
            {hasLoggedIn ? (
              <div className="settings-button">
                <div
                  className={`profile-logo-box ${hasLoggedIn ? "show" : ""}`}
                  ref={settingsRef}
                  onClick={() => {
                    setShowOptions(!showOptions);
                  }}
                >
                  <div className="profile-change">
                    <div className="profile-name" title={currentProfile?.name}>
                      {currentProfile?.name}
                    </div>
                  </div>

                  <div className="wrapper">
                    <img
                      alt="profile avatar"
                      className="profile-logo"
                      src={posterUrl}
                      onError={() => setPosterUrl(defaultProfileAvatar)}
                    ></img>
                  </div>
                  <div
                    className={`settings-options-box ${
                      showOptions ? "show" : ""
                    }`}
                  >
                    {allProfiles?.length > 1 ? (
                      <div>
                        {renderedProfiles}
                        <div className="seperator"></div>
                      </div>
                    ) : null}
                    <NavLink
                      to={{
                        pathname: `/profiles/all`
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="profile-option">
                        {localizationStrings.profileManagement}
                      </div>
                    </NavLink>
                    <NavLink
                      to={{
                        pathname: `/dashboard/settings`
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="profile-option"
                        onClick={() => setShowOptions(false)}
                      >
                        {localizationStrings.settings}
                      </div>
                    </NavLink>
                    <NavLink
                      to={
                        {
                          //pathname: `/dashboard/home`
                        }
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="profile-option"
                        onClick={() => clickedLogout()}
                      >
                        {localizationStrings.logout2}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`login-button-box ${hasLoggedIn ? "show" : ""}`}>
                <Link to="/login">
                  <div className="button-login">
                    <span>{localizationStrings.signIn}</span>
                    <div className="seperator"></div>
                    <span>{localizationStrings.login.signUp}</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>

        {showNotification ? (
          <Notification
            text={notificationText}
            confirmText={localizationStrings.yes}
            notConfirmText={localizationStrings.no}
            onClickConfirm={() => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                handleLogout();
                history.replace("/login");
              }, 2000);
            }}
            onClickNotConfirm={clickNotConfirm}
          />
        ) : null}
      </nav>{" "}
      {isLoading && (
        <div className="landing-container">
          <div className="main-loader-wrapper">
            <div className="main-loader">
              <img src={mainLoadingGif} />
            </div>
            <div className="loading-text">
              {localizationStrings.mainLoadingText}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const NavbarProfileItem = ({ item }) => {
  const [profileAvatar, setProfileAvatar] = useState(null);

  useEffect(() => {
    if (item) {
      if (item.kid) {
        item.photo = "kids" + item.posterName;
      }
      setProfileAvatar(
        `${process.env.PUBLIC_URL}/profilePosters/${item?.posterName}.png`
      );
    }
  }, [item]);

  return (
    <NavLink
      to={{
        pathname: `/profiles/${item.id}`
      }}
      style={{ textDecoration: "none" }}
    >
      <div className="profile-option-item">
        <div className="profile-img-wrapper">
          <img
            src={profileAvatar}
            alt="profile avatar"
            onError={() => setProfileAvatar(defaultProfileAvatar)}
          />
        </div>
        <div className="profile-name">
          {decodeURIComponent(decodeURIComponent(item?.name))}
        </div>
      </div>
    </NavLink>
  );
};

export default Navbar;
